import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import ChatPage from './components/ChatPage'
import { useAuthenticate } from './api/googleauth'

const App = () => {
  const { user, loading } = useAuthenticate();

  if (loading) return 
    <div className="App">
      <div>Loading...</div>
    </div>;

  return (
    // <div className="App">
    //   <ChatPage></ChatPage>
    // </div>
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={user ? <Navigate to="/" replace /> : <LoginPage />} />
          <Route path="/" element={user ? <ChatPage /> : <Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
