import React, { useState, useEffect } from 'react';
import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';
import { useOpenAIAPI } from '../api/openapi';
//import {formatDate} from '../api/newsapi' //, get_news, summarize_news_prompt
import { useAuthenticate } from '../api/googleauth'
import { backendConfig } from '../api/googleauth'

const ChatPage = () => {
    const [messages, setMessages] = useState([]);

    const { TTS, chatCompletion } = useOpenAIAPI();

    const { signOutUser, getToken } = useAuthenticate();

    useEffect(() => {
        const verifyUser = async () => {
            const jwt_token = await getToken();
            try {
                const response = await fetch(backendConfig['checkAuth_ENDPOINT'], {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwt_token}`
                    }
                });

                if (!response.ok) {
                    alert('Failed to authenticate');
                    await signOutUser();
                    throw new Error('Failed to authenticate');
                }
                
            } catch (error) {
                console.error('Error:', error.message);
            }
        }
        verifyUser();
    });

    // Load chat history from localStorage when the component mounts
    useEffect(() => {
        //let today_date = formatDate(new Date());
        //let added_content = "\nToday's date is {"+today_date+"}."
        
        let initial_prompt = [];
        localStorage.setItem('chatMessages', JSON.stringify(initial_prompt));
        const storedMessages = JSON.parse(localStorage.getItem('chatMessages'));
        if (storedMessages) {
            setMessages(storedMessages);
        }
        
        // console.log(storedMessages)
    }, []);

    // Save chat history to localStorage whenever messages change
    useEffect(() => {
        localStorage.setItem('chatMessages', JSON.stringify(messages));
    }, [messages]);

    const onPlayMessage = async (mes) => {
        const url = await TTS(mes);
        const audio = new Audio(url);
        await audio.play(); // Play the audio directly in the browser    
        return Promise.resolve();
    };

    const handleSendMessage = async (message) => {
        setMessages(prevMessages => [...prevMessages, {'role':'user', 'content': message}]);
        let chat_response = await chatCompletion(message);
        console.log(chat_response)
        let response_content = chat_response
        console.log(response_content);
        setMessages(prevMessages => [...prevMessages, response_content]);
        if(response_content && response_content['content'])
        {
            //await onPlayMessage(response_content['content']);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col">
            {/* <h1 className="text-center text-3xl font-bold p-4">Live Chat</h1> */}
            
            <div className="flex items-center justify-between p-4">
                <h1 className="text-3xl font-bold flex-1 text-center">Live Chat</h1>
                <button
                    onClick={signOutUser}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                    Logout
                </button>
            </div>
            
            <div className="flex-grow p-4">
                <ChatHistory messages={messages} />
            </div>
            <ChatInput onSendMessage={handleSendMessage} />
        </div>
    );
}

export default ChatPage;