import React, { useState, useRef, useEffect } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { useOpenAIAPI } from '../api/openapi';

const ChatInput = ({ onSendMessage }) => {
    const [input, setInput] = useState('');
    //const [isRecording, setIsRecording] = useState(false);
    //const [ blobRef, setBlobRef ] = useState("");
    const { STT } = useOpenAIAPI();
    // const handleRecordingStop = async () => {
    //     const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
    //     // Here you would typically send the audioBlob to an API for transcription
    //     const url = URL.createObjectURL(audioBlob)
    //     console.log("Recording stopped. Blob is ready to be sent.");
    //     let speech_in_text = await STT(audioBlob);
    //     console.log(speech_in_text);
    // };

    const [recorder, setRecorder] = useState(null);
    const [scMediaStream, setSCMediaStream] = useState(null);
    const [isRecordingSC, setIsRecordingSC] = useState(false);

    // Start screen sharing
    const startRecordInternalAudio = async () => {
        if(scMediaStream)
        {
            await handleStartRecordingSC(new MediaStream(scMediaStream.getAudioTracks()));
        }
        else
        {
            alert("Start recording screen before recording internal audio!");
        }
    }

    const startScreenCapture = async () => {
        try {
            if(!scMediaStream)
            {
                const stream = await navigator.mediaDevices.getDisplayMedia({
                    video: {
                        width: 0,
                        height: 0,
                        frameRate: 0,
                        displaySurface: "monitor"
                    },
                    audio: {
                        suppressLocalAudioPlayback: false,
                        channelCount: 2,
                        noiseSuppression: false,
                        autoGainControl: false,
                        echoCancellation: false
                    },
                    systemAudio: "include",
                });
                setSCMediaStream(stream);
                //const newStream = new MediaStream(stream.getAudioTracks());
                
                //return newStream;
            }
            //const newStream1 = new MediaStream(scMediaStream.getAudioTracks());
            //return newStream1;
            //handleStartRecordingSC(scMediaStream);
        } catch (error) {
            console.error('Error capturing screen:', error);
            alert('Failed to start screen capture.');
        }
    };

    const stopScreenCapture = async () => {
        if(scMediaStream)
        {
            await scMediaStream.getTracks().forEach(track => track.stop()); // Stop all tracks
            setSCMediaStream(null);
        }
    };

    // Start recording with MediaRecorder
    const handleStartRecordingSC = async (stream) => {
        if(stream)
        {
            const options = { mimeType: 'audio/webm' };//audio/webm
            const newRecorder = new MediaRecorder(stream, options);
            setRecorder(newRecorder);

            newRecorder.ondataavailable = async (event) => {
                if (event.data.size > 0 && recorder) {
                    const blob = event.data;
                    const result = await STT(blob); // Implement this function as needed
                    await onSendMessage(result['text']);
                }
            };
            newRecorder.start();
            setIsRecordingSC(true);
        }
        else
        {
            alert("Start recording screen before recording internal audio!");
        }
    };

    const handleStopRecordingSC = async () => {
        if (recorder) {
            recorder.stop();
            setIsRecordingSC(false);
        }
    };

    const {
        status,
        startRecording,
        stopRecording,
    } = useReactMediaRecorder({
        audio: true,
        type: "audio/wav",
        onStop: (blobUrl, blob) => {
            console.log("Recording stopped. Blob URL:", blobUrl);
            if(blob && blob.size > 0)
            {
                handleSendAudio(blob);
            }
            
        }
    });

    const handleSendAudio = async (audioblob) => {
        let text = await STT(audioblob);
        if(text)
        {
            onSendMessage(text['text']);
        }
    };


    const handleSend = () => {
        onSendMessage(input);
        setInput('');
    };

    const handleInput = (event) => {
        setInput(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSend();
        }
    };

    return (
        <div className="p-4 bg-gray-200">
            <input 
                type="text" 
                value={input}
                onChange={handleInput}
                disabled={status === "recording"}
                className="border p-2 rounded w-full"
                placeholder="Type your message..."
                onKeyDown={handleKeyDown}
            />
            <div>
                <button 
                    onClick={handleSend} 
                    className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    disabled={status === "recording"}
                >
                    Send
                </button>
                    {status === "recording" ? (
                        <>
                            <button onClick={stopRecording} className="mt-2 ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                Stop Recording
                            </button>
                            <span className="absolute top-0 right-0 m-4 text-xl text-red-500">
                                <i className="fas fa-microphone-alt"></i> Recording...
                            </span>
                        </>
                        
                        
                    ) : (
                        <button onClick={startRecording} className="mt-2 ml-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                            Start Recording
                        </button>
                    )}
                    {isRecordingSC ? (
                        <button onClick={async () => {await handleStopRecordingSC()}} className="mt-2 ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Stop Internal Audio Recording
                        </button>
                    ) : (
                        <button onClick={async () => {await startRecordInternalAudio();}} className="mt-2 ml-2 bg-yellow-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Start Internal Audio Recording
                        </button>
                    )}
                    {scMediaStream ? (
                        <button onClick={async () => {await stopScreenCapture();}} className="mt-2 ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Stop Screen Recording
                        </button>
                    ) : (
                        <button onClick={async () => {await startScreenCapture();}} className="mt-2 ml-2 bg-purple-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Start Screen Recording
                        </button>
                    )}
            </div>
        </div>
    );
}

export default ChatInput;