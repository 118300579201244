import { getAuth, signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import app from './firebaseConfig';

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const backendConfig = {
  checkAuth_ENDPOINT: "https://api.ai-interview-helper.com/checkauth"//'http://localhost:4000/checkauth'
}

export const useAuthenticate = () => {
  const [user, loading, error] = useAuthState(auth);

  const signInWithGoogle = async () => {
    await signInWithPopup(auth, provider);
  };

  const signOutUser = async () => {
    await signOut(auth);
  };

  const getToken = async () => {
    return user ? await user.getIdToken() : null;
  };

  return { user, loading, error, signInWithGoogle, signOutUser, getToken };
};

export {backendConfig};