import React from 'react';
import { useAuthenticate } from '../api/googleauth';  // Ensure you have this hook set up to handle authentication

const LoginPage = () => {
  const { signInWithGoogle, user, getToken } = useAuthenticate();

  return (
    <div className="h-screen flex justify-center items-center">
      <button
        onClick={signInWithGoogle}
        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
      >
        Sign in with Google
      </button>

      <button
        onClick={async () => {const token1 = await getToken(); console.log(token1)}}
        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
      >
        GetToken
      </button>
    </div>
  );
};

export default LoginPage;