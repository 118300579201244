import React from 'react';
import { useState } from 'react';
import { useOpenAIAPI } from '../api/openapi';
const ChatHistory = ({ messages }) => {
    
    const [hoverIndex, setHoverIndex] = useState(-1);

    const { TTS } = useOpenAIAPI();

    const onPlayMessage = async (mes) => {
        const url = await TTS(mes);
        const audio = new Audio(url);
        await audio.play(); // Play the audio directly in the browser
        // or create a download link
        // const a = document.createElement("a");
        // a.href = url;
        // a.download = "output.mp3"; // Specify the filename to download
        // a.click(); // Programmatically click the link to trigger the download
    
        return Promise.resolve();
    };

    return (
        <div className="overflow-auto h-full bg-white shadow p-4">
            {messages.map((message, index) => (
                (message['role'] === 'system' || message['function_call'])  ? <></> 
                : 
                // <div key={index} 
                //     className="p-2 my-2 rounded bg-blue-100">
                //     {message['role']}: {message['content']}
                // </div>
                <div
                    key={index}
                    className="flex items-center space-x-2 p-2 my-2 rounded bg-blue-100 relative"
                    onMouseEnter={() => {setHoverIndex(index)}}
                    onMouseLeave={() => setHoverIndex(-1)}
                >
                    <div className="flex-grow">{message['role']}: {message['content']}</div>
                    {hoverIndex === index && (
                        <button
                            onClick={async () => await onPlayMessage(message['content'])}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                        >
                            Play
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
}

export default ChatHistory;