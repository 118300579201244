import { useAuthenticate } from './googleauth'

const openAIAPIConfig = {
    API_KEY: "", //openai_apikey
    API_CHATCOMPLETION_ENDPOINT: "https://api.ai-interview-helper.com/chat",//"http://localhost:4000/chat",
    API_TTS_ENDPOINT: "https://api.ai-interview-helper.com/tts",//"http://localhost:4000/tts",
    API_STT_ENDPOINT: "https://api.ai-interview-helper.com/stt",//"http://localhost:4000/stt",
};

const useOpenAIAPI = () => {
  const { getToken } = useAuthenticate();

  const chatCompletion = async ( prompt ) => {
      let context = JSON.parse(localStorage.getItem("chatMessages"));
      if(context == null)
      {
          return "";
      }
      context = [
          ...context,
          {'role': "user", 'content': prompt}
      ];
      console.log(context)
      // console.log(JSON.stringify({
      //     "model": openAIAPIConfig['speech_model_name'],
      //     "messages": context,
      //     "temperature": openAIAPIConfig['temperature']
      // }));
      let response = {};
      try {
        const googleAuthToken = await getToken();
        response = await fetch(openAIAPIConfig['API_CHATCOMPLETION_ENDPOINT'], {
            method: "POST",
            // body: JSON.stringify(
            //     {
            //         "model": openAIAPIConfig['speech_model_name'],
            //         "messages": [{"role": "user", "content": prompt}],
            //         "temperature": openAIAPIConfig['temperature'],
            //         'functions': get_news_function,
            //         "function_call": 'auto'
            //     }
            // ),
            body: JSON.stringify({
                "content": context,
            }),
            headers: {
                'Content-Type': "application/json",
                'Authorization': "Bearer " + googleAuthToken  // Corrected
            }
          }
        )
      } catch (error) {
        console.error('Error fetching OpenAI response:', error.response ? error.response.data : error.message);
      }
      
      // .then(
      //     (res) => {
      //         response = res;
      //     }
      // )
      response = await response.json();
      return response;
  };

  const TTS = async (text) => {
    if(text == null){
      return "";
    }
    //console.log(text)
    const googleAuthToken = await getToken();
    let response = await fetch(openAIAPIConfig['API_TTS_ENDPOINT'],{
        method: "POST",
        body: JSON.stringify({
          "input": text,
        }),
        headers:{
          'Content-Type': "application/json",
          'Authorization': "Bearer " + googleAuthToken  // Corrected
        }
      }
    )
    if (!response.ok) {
      throw new Error('Failed to fetch TTS data: ' + response.statusText);
    }

    const blob = await response.blob(); // Handling binary data
    const url = URL.createObjectURL(blob); // Creating a URL for the blob
    console.log(url);
    return url;
  }

  const STT = async (audioBlob) => {
    if (!audioBlob || audioBlob.size === 0) {
      throw new Error('STT: audioblob is null or empty!');
    }
    //console.log(audioBlob)
    const googleAuthToken = await getToken();
    try {
        const formData = new FormData();
        const audioFile = new File([audioBlob], 'voice.wav', { type: 'audio/wav' });
        formData.append('file', audioFile);
        //formData.append('model', openAIAPIConfig['audiotospeech_model_name']);

        const response = await fetch(openAIAPIConfig['API_STT_ENDPOINT'], {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${googleAuthToken}`,
                //'Content-Type': "multipart/form-data",
                // Additional headers might be required depending on OpenAI's API specifications
            },
            body: formData
                
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error sending audio to OpenAI:', error);
        return null;
    }
  };

  return { chatCompletion, TTS, STT };
}

export {useOpenAIAPI};
